import * as React from "react"
import Container from "./container"

export default function Section(props) {
  const { children, ...otherProps } = props
  return (
    <section className="p-3 bg-primary-200" {...otherProps}>
      <Container>{children}</Container>
    </section>
  )
}
